
import { computed, nextTick, watch } from "vue"
import echarts from "echarts"
import { ECHARTS_GRID } from "@/views/report/script/constant"
import { useI18n } from "vue-i18n"

interface Props {
  scoreList?: { time: string; value: number }[]
}

export default {
  props: ["scoreList", "averageScore"],
  setup(props: Props) {
    const { t } = useI18n()
    const option = computed(() => {
      return {
        grid: ECHARTS_GRID,
        xAxis: {
          interval: 2,
          max: props.scoreList?.length,
          min: 1,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisLabel: {
            textStyle: {
              color: "rgba(255,255,255,.75)" //更改坐标轴文字颜色
            }
          }
        },
        yAxis: {
          interval: 20,
          max: 100,
          min: 0,
          splitLine: {
            lineStyle: {
              color: "rgba(19, 43, 81, .3)",
              width: 1,
              type: "solid"
            }
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            textStyle: {
              color: "rgba(255,255,255,.75)" //更改坐标轴文字颜色
            }
          }
        },
        series: [
          {
            symbolSize: 4,
            data: props.scoreList
              ?.map((it, index) => [index + 1, it.value])
              .filter((it) => it[1]),
            type: "scatter",
            itemStyle: {
              normal: {
                color: "#5cabd2"
              }
            }
          }
        ]
      }
    })
    let ref: HTMLDivElement
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let echartsDom: echarts.ECharts
    const setRef = (el: HTMLDivElement) => (ref = el)
    nextTick(() => {
      echartsDom = echarts.init(ref)
      echartsDom.setOption(option.value as echarts.EChartOption)
    })

    watch(
      () => option.value,
      (val) => {
        echartsDom?.setOption(val as echarts.EChartOption)
      }
    )
    return { setRef }
  }
}
