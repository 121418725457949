
import { ref, watch } from "vue"
import DateType from "./components/DateType.vue"
import MonthlyCalendar from "./components/MonthlyCalendar.vue"
import SleepTimeAnalyse from "./components/SleepTimeAnalyse.vue"
import MonthScoreAnalyse from "./components/MonthScoreAnalyse.vue"
import SoberAnalyse from "./components/SoberAnalyse.vue"
import Position from "./components/Position.vue"
import { addMonth } from "@/assets/script/utils/date.util"
import { duration } from "@/assets/script/utils/sleep-ace.util"
import {
  MonthSleepScoreVo,
  SleepAceService
} from "@/assets/script/service/sleep-ace.service"
import { Ref, UnwrapRef } from "@vue/reactivity"
import { Toast } from "vant"
import { useI18n } from "vue-i18n"

interface Props {
  equipmentId: string
  date?: string
}

export default {
  props: ["equipmentId", "date"],
  components: {
    DateType,
    MonthlyCalendar,
    SleepTimeAnalyse,
    MonthScoreAnalyse,
    SoberAnalyse,
    Position
  },
  setup(props: Props) {
    const { t } = useI18n()
    const dialogShow = ref(false)
    const currDate = ref(props.date ? new Date(props.date) : new Date())
    const leftRight: Ref<UnwrapRef<0 | 1>> = ref(0)
    const emptyMessage = ref("")
    const changeDate = (step: number) => {
      currDate.value = addMonth(currDate.value, step)
    }
    const sleepData: Ref<UnwrapRef<MonthSleepScoreVo | undefined>> = ref(
      undefined
    )
    const listMonthSleepScore = async () => {
      const toast = Toast.loading({
        message: "加载中...",
        forbidClick: true,
        loadingType: "spinner"
      })
      const result = await SleepAceService.getMonthData({
        equipmentId: props.equipmentId,
        leftRight: leftRight.value,
        month: currDate.value.getMonth() + 1,
        year: currDate.value.getFullYear()
      })
      emptyMessage.value = result.message ?? ""
      toast.clear()
      sleepData.value = result.data
    }

    watch(
      () => {
        return {
          date: currDate.value,
          leftRight: leftRight.value
        }
      },
      async () => {
        await listMonthSleepScore()
      },
      { immediate: true }
    )
    return {
      dialogShow,
      emptyMessage,
      currDate,
      leftRight,
      changeDate,
      sleepData,
      duration
    }
  }
}
