
import { computed, reactive, watch } from "vue"
import { addMonth, equalsMonth } from "@/assets/script/utils/date.util"
import { MonthlyCalendar } from "./script/model"

interface Props {
  modelValue: Date
}

interface State {
  date: Date
  year: number
  firstMonthDate: Date
  calendarDataList: MonthlyCalendar[]
}

export default {
  props: ["modelValue"],
  setup(props: Props) {
    const state: State = reactive({
      date: computed(() => props.modelValue),
      year: props.modelValue.getFullYear(),
      firstMonthDate: computed(() => new Date(state.year, 0, 1)),
      calendarDataList: computed(() => {
        const calendarDataList = []
        const { firstMonthDate } = state
        for (let i = 0; i < 12; i++) {
          const date = addMonth(firstMonthDate, i)
          const state = ""
          const disabled = false
          const active = equalsMonth(date, props.modelValue)
          calendarDataList.push(
            new MonthlyCalendar(
              date.getFullYear(),
              date.getMonth() + 1,
              date,
              state,
              disabled,
              active
            )
          )
        }
        return calendarDataList
      })
    })
    const change = (step: number) => {
      state.year += step
    }
    watch(
      () => state.date,
      (val) => {
        state.year = val.getFullYear()
      }
    )
    return {
      change,
      state
    }
  }
}
