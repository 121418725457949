
import { computed, nextTick, watch } from "vue"
import echarts from "echarts"
import { ECHARTS_GRID } from "@/views/report/script/constant"
import { duration } from "@/assets/script/utils/sleep-ace.util"
import { useI18n } from "vue-i18n"

interface Props {
  sleepTimeList?: { time: string; value: number }[]
}

export default {
  props: ["sleepTimeList", "averageSleepTime"],
  setup(props: Props) {
    const { t } = useI18n()
    const option = computed(() => {
      return {
        grid: ECHARTS_GRID,
        xAxis: {
          type: "category",
          interval: 2,
          min: 0,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisLabel: {
            textStyle: {
              color: "rgba(255,255,255,.75)" //更改坐标轴文字颜色
            }
          },
          data: props.sleepTimeList?.map((it, index) => `${index + 1}`) ?? []
        },
        yAxis: {
          type: "value",
          interval: 2,
          max: 10,
          min: 0,
          splitLine: {
            lineStyle: {
              color: "rgba(19, 43, 81, .3)",
              width: 1,
              type: "solid"
            }
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            textStyle: {
              color: "rgba(255,255,255,.75)" //更改坐标轴文字颜色
            }
          }
        },
        series: [
          {
            data: props.sleepTimeList?.map((it) => it.value / 60) ?? [],
            type: "bar",
            itemStyle: {
              barBorderRadius: [10, 10, 0, 0],
              color: "#5cabd2"
            }
          }
        ]
      }
    })
    let ref: HTMLDivElement
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let echartsDom: echarts.ECharts
    const setRef = (el: HTMLDivElement) => (ref = el)
    nextTick(() => {
      echartsDom = echarts.init(ref)
      echartsDom.setOption(option.value as echarts.EChartOption)
    })

    watch(
      () => option.value,
      (val) => {
        echartsDom?.setOption(val as echarts.EChartOption)
      }
    )
    return { setRef, duration }
  }
}
